
class BackTop {
    constructor (selecteur, options = {}) {

        this.selecteur = selecteur;
        this.options = Object.assign({
            type: "slide--bottom", // options default
            // onShow: ()=>{}
        }, options);

        // this.options.onShow()
        this.selecteur.classList.add('backTop__' + this.options.type, 'hide')
        this.selecteur.addEventListener('click', this.handleClick)
        window.addEventListener('scroll', (e) => this.handleScroll(e))

    }

    handleClick(){
        window.scrollTo({
            top: 0,
        })
    }

    handleScroll(e){
        if((document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) ) {
            this.selecteur.classList.remove("hide");
        } else {
            this.selecteur.classList.add("hide");
        }   
    }
}

const backTop = new BackTop(document.querySelector('#topButton'));


// Bouton show video
document.querySelectorAll('.btn-video-result').forEach((elm) => {
    elm.addEventListener('click', async (e) => {

        const type = e.currentTarget.dataset.type;
        const id = e.currentTarget.dataset.id;

        const rowVideo = document.querySelector(`.row-video[data-type="${type}"][data-id="${id}"]`);
        rowVideo.classList.toggle('d-none');
        rowVideo.querySelector('.video-ctn').innerHTML = '';

        if (!rowVideo.classList.contains('d-none')) {

            // Recup les url de video
            const resp = await fetch(rowVideo.querySelector('.video-ctn').dataset.url);
            const urlDigitalOcean = await resp.json();

            const videoDOM = document.createElement('video');
            videoDOM.classList.add(...['video-js', 'vjs-big-play-centered']);
            videoDOM.setAttribute('responsive', true);
            videoDOM.setAttribute('controls', true);
            videoDOM.setAttribute('preload', 'auto');

            const getUrl = window.location;
            const baseUrl = getUrl.protocol + "//" + getUrl.host;

            videoDOM.setAttribute('poster', baseUrl+'/img/opengraph/opengraph.jpg');
            videoDOM.style.margin = 'auto';

            const sourceDOM = document.createElement('source');
            sourceDOM.setAttribute('src', urlDigitalOcean);
            //sourceDOM.setAttribute('src', 'https://video.wstapi.fr/135/4354/478_1.mp4');
            sourceDOM.setAttribute('type', 'video/mp4');
            
            rowVideo.querySelector('.video-ctn').append(videoDOM);
            videoDOM.append(sourceDOM);
            //Users/innovdata/Sites/preprod-otc-creo/public/img/opengraph/opengraph.jpg
            videojs(videoDOM, {});
        }

        // Hide all other video
        document.querySelectorAll(`.row-video`).forEach((elm) => {
            if (elm != rowVideo) {
                elm.classList.add('d-none');
                elm.querySelector('.video-ctn').innerHTML = '';
            }
        });

    });
});